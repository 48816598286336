*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    font-size: 62.5%;
}

.p-container {
    display: grid;
    grid-template-rows: 5vh 15vh 18vh 25vh 24vh min-content min-content min-content min-content min-content max-content min-content 1fr 1fr;
    grid-template-columns: minmax(6rem, 1fr) repeat(4, minmax(min-content, 1fr)) minmax(6rem, 1fr);
}

.p-app-container {
    display: grid;
    grid-template-rows: 5vh 28vh 18vh 25vh 24vh min-content 10vh min-content;
    grid-template-columns: minmax(6rem, 1fr) repeat(4, minmax(min-content, 1fr)) minmax(6rem, 1fr);
}

.container {
    display: grid;
    grid-template-rows: 5vh 15vh 20vh 10vh 20vh 10vh 10vh 10vh repeat(30, min-content) 10vh;
    grid-template-columns: repeat(2, minmax(1rem, 1fr)) repeat(4, minmax(min-content, 0.5fr)) repeat(2, minmax(1rem, 1fr));
}

.greenOnHover:hover{
    color: #42b79d;
    
}

.dateCheckboxes {
    color: white;
    padding: 0em;
    margin: 0em;
    margin-top: 0em;

}



body {
    font-weight: 300;
    line-height: 1.6;
    font-family: Muli;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0.1px;
    color: white;
    font-size: 1.8rem;
    letter-spacing: 0.3px;
    line-height: 180%;
    background-color: #a73a30;
}

.form-control-lg {
    font-size: 1.5rem;
}

b {
    color: white;
}

.mb-sm {
    margin-bottom: 2rem;
}

.mb-md {
    margin-bottom: 3rem;
}

.mb-lg {
    margin-bottom: 4rem;
}

.mb-hg {
    margin-bottom: 8rem;
}

.h4 {
    font-size: 1.8rem;
}

.navb {
    grid-column: 1/-1;
    grid-row: 1 / span 1;
    display: flex;
}

.navb .nav-bottom {
    list-style: none;
    margin-top: 0.5em;
    align-items: center;
    justify-content: right;
    font-size: 2em;
    align-content: right;
    justify-items: right;
}

.navb .nav-bottom__link:link,
.navb .nav-bottom__link:visited {
    color: #aaa;
    font-family: "Josefin Sans", sans-serif;
    text-align: center;
    display: block;
    text-decoration: none;
    border: 0;
    transition: all 0.2s;
}

.navb .nav-bottom__link:hover,
.navb .nav-bottom__link:active {
    background-color: rgba(255, 255, 255, 0.05);
    text-decoration: none;
    border: 0;
    transform: translateY(3px);
}

@media only screen and (min-width: 600px) {
    .first-page-header {
        grid-column: 2 / span 6;
        grid-row: 4 / span 3;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 100%;
        justify-items: center;
        justify-self: center;
        background: url("../img/malmocontech_logo.svg") no-repeat;
    }

    .first-page-header:hover {
        background: url("../img/malmocontech_logo_green.svg") no-repeat;
    }
}

@media only screen and (max-width: 600px) {
    .first-page-header {
        grid-column: 1 / -1;
        grid-row: 4 / span 3;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 100%;
        justify-items: center;
        justify-self: center;
        background: url("../img/malmocontech_logo.svg") no-repeat;
    }

    .first-page-header:hover {
        background: url("../img/malmocontech_logo_green.svg") no-repeat;
    }
}

.second-page {
    grid-column: 2 / span 4;
    grid-row: 7 / span 1;
    display: flex;
}
/* .list-group-item:hover{
    border:1px solid #42b79d;
    border-radius: 0.2em;
    

} */
.second-v-line {
    grid-column: 5;
    grid-row: 5 / span 5;
    border-left: 0.1px solid lightgrey;
    height: 90%;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-self: center;
    align-content: center;
}

hr,
.second-h-line {
    grid-column: 1 /-1;
    grid-row: 12;
    border-left: 2px solid lightgrey;
    z-index: -50;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    align-self: center;
    margin-left: 2em;
    margin-right: 2em;
}

.underButton {
    display: flex;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center bottom;
}

.info-button {
    display: flex;
    padding: 2%;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.1px;
    font-size: 1.8rem;
    text-transform: none;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: center;
    width: 40%;
    z-index: 100;
    background-color: lightgrey;
    border-width: 0;
    margin-bottom: 2em;
    color: #a73a30;
    transition: 0.2s;
    border-radius: 0.2em;
    margin: auto;
}

.info-button:hover {
    color: #42b79d;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.1px;
    font-size: 1.8rem;
    text-transform: none;
    justify-content: center;
    align-items: center;
    z-index: 100;
    background-color: white;
    transform: translateY(3px);
}

@media only screen and (min-width: 600px) {
    .byggregler-pic {
        margin-top: 1em;
        border: 1px solid lightgrey;
        grid-column: 2 / -2;
        grid-row: 9 / span 10;
        border-radius: 0.2em;
        background-repeat: no-repeat;
        background-size: auto;
        background-position: center bottom;
        transition: 0.1s;
    }

    .byggregler-pic img {
        object-fit: scale-down;

    }

    .byggregler-pic a:hover,
    .byggregler-pic .h3ader:hover {
        color: #42b79d;
        /*text-decoration: ;*/
    }

    .info-header-2 {
        margin-top: 2em;
        grid-column: 1 / -1;
        grid-row: 21 / span 1;
        display: grid;
        justify-content: center;
        align-content: center;
        padding: 2em;
    }
}

@media only screen and (max-width: 600px) {
    .byggregler-pic {
        margin-top: 1em;
        border: 1px solid lightgrey;
        grid-column: 1 / -1;
        grid-row: 9 / span 10;
        border-radius: 0.2em;
        background-repeat: no-repeat;
        background-size: auto;
        background-position: center bottom;
        transition: 0.1s;

    }

    .byggregler-pic img {
        object-fit: scale-down;
    }

    .byggregler-pic a:hover,
    .byggregler-pic .h3ader:hover {
        color: #42b79d;
    }

    .info-header-2 {
        grid-column: 1 / -1;
        grid-row: 21 / span 1;
        display: grid;
        justify-content: center;
        align-content: center;
        margin: 2em;
    }
}

.far,
.fa-arrow-alt-circle-down,
.info-header {
    grid-column: 1 / -1;
    grid-row: 8 / span 2;
    display: grid;
    justify-content: center;
    align-content: center;
    font-size: 1.2em;
    transition: 0.1s;
    color: white;
    text-decoration: none;
}



.far:hover,
.fa-arrow-alt-circle-down:hover,
.info-header:hover {
    color: #42b79d;
}

.info-header {
    display: flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
}

.list-group-item {
    background-color: #a73a30;
    /* border: 1px solid transparent; */
}

.info-text-contech {
    margin: 1em;
}

.form-check-input {
    /* margin-top: 1rem; */
}

@media only screen and (min-width: 600px) {
    .ContactForm {
        margin-bottom: 3em;
        grid-column: 3 / -3;
        grid-row: 22 / span 2;
        display: flex;
        justify-content: center;
        align-content: center;
    }

    .ContactForm .form-control-lg {
        background-color: lightgrey;
        font-size: 1em;
    }

    .ContactForm .signup-checkbox {
        font-size: 1.4rem;
    }

    .ContactForm .formular {
        font-family: Muli;
        font-style: normal;
        font-weight: normal;
        letter-spacing: 0.1px;
        color: white;
        font-size: 1.4rem;
        letter-spacing: 0.3px;
        line-height: 180%;
        width: 100%;
    }

    .ContactForm .formular-form {
        height: 100%;
    }

    .ContactForm .buttonRegistrera {
        padding: 5%;
        font-family: Muli;
        font-style: normal;
        font-weight: normal;
        letter-spacing: 0.1px;
        font-size: 1.8rem;
        text-transform: none;
        justify-content: center;
        align-items: center;
        width: 100%;
        z-index: 100;
        background-color: lightgrey;
        border-width: 0;
        margin-bottom: 2em;
        color: #a73a30;
        transition: 0.2s;
        border-radius: 0.2em;
    }

    .ContactForm .buttonRegistrera:hover {
        color: #42b79d;
        font-family: Muli;
        font-style: normal;
        font-weight: normal;
        letter-spacing: 0.1px;
        font-size: 1.8rem;
        text-transform: none;
        justify-content: center;
        align-items: center;
        z-index: 100;
        background-color: white;
        transform: translateY(3px);
    }

    .ContactForm .buttonRegistrera:disabled {
        color: grey;
        background-color: lightgrey;
        transform: none;
    }
}

@media only screen and (max-width: 600px) {
    .ContactForm {
        grid-column: 1 / -1;
        grid-row: 22 / span 2;
        display: flex;
        justify-content: center;
        align-content: center;
    }

    .ContactForm .form-control-lg {
        background-color: lightgrey;
        font-size: 1em;
    }

    .ContactForm .signup-checkbox {
        font-size: 1.2rem;
    }

    .ContactForm .formular {
        font-family: Muli;
        font-style: normal;
        font-weight: normal;
        letter-spacing: 0.1px;
        color: white;
        font-size: 1.4rem;
        letter-spacing: 0.3px;
        line-height: 180%;
        width: 100%;
    }

    .ContactForm .formular-form {
        height: 100%;
    }

    .ContactForm .buttonRegistrera {
        padding: 5%;
        font-family: Muli;
        font-style: normal;
        font-weight: normal;
        letter-spacing: 0.1px;
        font-size: 1.8rem;
        text-transform: none;
        justify-content: center;
        align-items: center;
        width: 100%;
        z-index: 100;
        background-color: lightgrey;
        border-width: 0;
        margin-bottom: 2em;
        color: #a73a30;
        transition: 0.2s;
        border-radius: 0.2em;
    }

    .ContactForm .buttonRegistrera:hover {
        color: #42b79d;
        font-family: Muli;
        font-style: normal;
        font-weight: normal;
        letter-spacing: 0.1px;
        font-size: 1.8rem;
        text-transform: none;
        justify-content: center;
        align-items: center;
        z-index: 100;
        background-color: white;
        transform: translateY(3px);
    }

    .ContactForm .buttonRegistrera:disabled {
        color: grey;
        background-color: lightgrey;
        transform: none;
    }
}

.underButton {
    display: flex;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center bottom;
}

.previous-video {
    display: flex;
    padding: 1px;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.1px;
    font-size: 1.8rem;
    text-transform: none;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: center;
    width: 40%;
    z-index: 100;
    background-color: lightgrey;
    border-width: 0;
    margin-bottom: 2em;
    color: #a73a30;
    transition: 0.2s;
    border-radius: 1px;
    margin: auto;
}


.previous-button {
    display: flex;
    padding: 2%;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.1px;
    font-size: 1.8rem;
    text-transform: none;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: center;
    width: 40%;
    z-index: 100;
    background-color: lightgrey;
    border-width: 0;
    margin-bottom: 2em;
    color: #a73a30;
    transition: 0.2s;
    border-radius: 0.2em;
    margin: auto;
}




.previous-button:hover {
    color: #42b79d;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.1px;
    font-size: 1.8rem;
    text-transform: none;
    justify-content: center;
    align-items: center;
    z-index: 100;
    background-color: white;
    transform: translateY(3px);
}

@media only screen and (min-width: 600px) {
    .previous-pic {
        margin-top: 1em;
        /*border: 1px solid lightgrey;*/
        grid-column: 2 / -2;
        grid-row: 24 / span 10;
        border-radius: 0.2em;
        background-repeat: no-repeat;
        background-size: auto;
        background-position: center bottom;
    }

    .previous-pic img {
        object-fit: scale-down;
    }

    .previous-pic a:hover,
    .previous-pic .h3ader:hover {
        color: #aaa;
        text-decoration: none !important;
    }

    .previous-header-2 {
        margin-top: 2em;
        grid-column: 1 / -1;
        grid-row: 21 / span 1;
        display: grid;
        justify-content: center;
        align-content: center;
        padding: 2em;
    }
}

@media only screen and (max-width: 600px) {
    .previous-pic {
        margin-top: 1em;
        /*border: 1px solid lightgrey;*/
        grid-column: 1 / -1;
        grid-row: 27 / span 10;
        border-radius: 0.2em;
        background-repeat: no-repeat;
        background-size: auto;
        background-position: center bottom;
    }

    .previous-video {
        display: flex;
        padding: 1px;
        font-family: Muli;
        font-style: normal;
        font-weight: normal;
        letter-spacing: 0.1px;
        font-size: 1.8rem;
        text-transform: none;
        justify-content: center;
        align-items: center;
        align-content: center;
        align-self: center;
        width: 30%;
        height: 30%;
        z-index: 100;
        background-color: lightgrey;
        border-width: 0;
        margin-bottom: 2em;
        color: #a73a30;
        transition: 0.2s;
        border-radius: 1px;
        margin: auto;



    }





    .previous-pic img {
        object-fit: scale-down;
    }

    .previous-pic a:hover,
    .previous-pic .h3ader:hover {
        color: #aaa;
        text-decoration: none !important;
    }

    .previous-header-2 {
        grid-column: 1 / -1;
        grid-row: 21 / span 1;
        display: grid;
        justify-content: center;
        align-content: center;
        margin: 2em;
    }
}

.far,
.fa-arrow-alt-circle-down,
.previous-header {
    grid-column: 1 / -1;
    grid-row: 8 / span 2;
    display: grid;
    justify-content: center;
    align-content: center;
    font-size: 1.2em;
    transition: 0.2s;
    color: white;
    text-decoration: none;
}

.far:hover,
.fa-arrow-alt-circle-down:hover,
.previous-header:hover {
    color: lightgrey;
    transform: translateY(3px);
}

.previous-header {
    display: flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
}

/* .list-group-item {
    background-color: #a73a30;
    margin-bottom:0px;
} */

.previous-text-contech {
    margin: 1em;
}

.footer {
    grid-column: 1/-1;
    grid-row: -1 / span 1;
    margin-top: 1em;
}

.footer .fab.fa-facebook-square {
    font-size: 27px;
    width: 27px;
    height: 31px;
}

.footer .fab.fa-linkedin-in {
    font-size: 27px;
    width: 27px;
    height: 31px;
}

.footer .fas.fa-share-alt {
    font-size: 27px;
    width: 27px;
    height: 31px;
}

.footerText {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 20px;
    /* or 182% */
    grid-column: 3 / span 2;
    letter-spacing: 0.2px;
    grid-row: 10 / span 2;
    /* Light / Gray dark */
    color: white;
    padding-top: 5rem;
    justify-content: center;
    align-items: center;
}

.copyright {
    font-size: 1.4rem;
    color: lightgrey;
    text-align: center;
}

.copyright a {
    font-size: 1.4rem;
    color: lightgrey;
    text-align: center;
}

.copyright a:hover {
    color: white;
}

.nav-bottom {
    margin-top: 1em;
    grid-column: 4 / span 2;
    grid-row: -2 / span 1;
    list-style: none;
    display: flex;
    grid-gap: 4rem;
    font-size: 2em;
    justify-content: center;
}

.nav-bottom__link:link,
.nav-bottom__link:visited {
    color: #42b79d;
    font-family: "Josefin Sans", sans-serif;
    text-align: center;
    display: block;
    text-decoration: none;
    border: 0;
    transition: all 0.2s;
}

.nav-bottom__link:hover,
.nav-bottom__link:active {
    text-decoration: none;
    border: 0;
    color: white;
    transform: translateY(3px);
}



.anchor-in-text {

    color: white;
    text-decoration: underline;

    transition: 0.1s;

}

.anchor-in-text:hover {

    color: #42b79d;
    text-decoration: underline;


}